import {
  EditorReadyFn,
  OnEventFn,
  HandleActionFn,
} from '@wix/yoshi-flow-editor';

const BAZAAR_APP_DEF_ID = 'd97efed6-9b76-4b39-b3a3-069adf30c67a';
export const MEMBERS_AREA_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
const MY_ACCOUNT_APP_DEF_ID = '14cffd81-5215-0a7f-22f8-074b0e2401fb';

const installMembersArea = async (editorSDK: any) => {
  const isMAInstalled = await editorSDK.document.tpa.isApplicationInstalled(
    '',
    {
      appDefinitionId: MY_ACCOUNT_APP_DEF_ID,
    },
  );
  if (!isMAInstalled) {
    const appDefinitionId = MEMBERS_AREA_APP_DEF_ID;

    await editorSDK.document.application.install('', {
      appDefinitionId: MEMBERS_AREA_APP_DEF_ID,
      originInfo: {
        appDefinitionId,
      },
    });
  }
};

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  platformOptions,
  flowAPI,
) => {
  debugger;
};

export const onEvent: OnEventFn = async (event) => {};

export const handleAction: HandleActionFn = async (
  { type, payload }: any,
  editorSDK: any,
) => {
  try {
    switch (type) {
      case 'appInstalled': {
        switch (payload.appDefinitionId) {
          case BAZAAR_APP_DEF_ID:
            debugger;
            return installMembersArea(editorSDK);
          default:
            return Promise.resolve();
        }
      }
      default:
        return Promise.resolve();
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const exports = {};
